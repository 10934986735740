/* eslint-disable max-classes-per-file */
import React, { createContext, useMemo } from 'react';
import bind from 'bind-decorator';
import _ from 'lodash';
import type { O, C } from 'ts-toolbelt';
import { snakeToCamel } from '@/helpers/app/string';

export interface OnboardingStep {
  isLockedFeature?: boolean;
  isReactRoute?: boolean;
  title?: string;
  subtitle?: string;
  buttonName?: string;
  buttonPath?: string;
  itemCategory?: string;
  complete?: boolean;
  isSubItem?: boolean;
  isExternalLink?: boolean;
  name?: string;
}

export interface UserInfoProps {
  userId: string;
  name: string;
  admin: boolean;
  audio: boolean;
  roles: Record<string, string>;
  avochatoRep: boolean;
  canCall: boolean;
  lastUsedFilter: Record<string, string>;
  email: string;
  phone: string;
  inboxMode: string;
  onboarding: OnboardingStep[];
  onboardedLooker: boolean;
  canViewBilling: boolean;
  url: string;
  canEditAvobot: boolean;
  canEditAgentAssist: boolean;
  agentAssistEnabled: boolean;
  unsendMessages?: boolean;
  avatar: string;
  initials: string;
  broadcastFailedAppNotificationsEnabled: boolean;
  missedCallsAppNotificationsEnabled: boolean;
  agentNeededAppNotificationsEnabled: boolean;
  ticketAssignmentAppNotificationsEnabled: boolean;
}

const UserInfoBase = (function klass() {
  class Klass {
    constructor(props: UserInfoProps) {
      Object.assign(this, props);
    }
  }
  return Klass as C.Class<[props: UserInfoProps], O.Assign<Klass, [UserInfoProps]>>;
}());

export class UserInfo extends UserInfoBase {
  @bind
  memberOf(subdomain: string) {
    return !!this.roles[snakeToCamel(subdomain)];
  }

  @bind
  canManage(subdomain: string) {
    return ['manager', 'owner'].includes(this.roles[snakeToCamel(subdomain)]);
  }

  @bind
  getMemberships() {
    return Object.keys(_.pickBy(this.roles, _.identity));
  }

  @bind
  getManagedSubdomains() {
    return Object.keys(_.pickBy(this.roles, Array.prototype.includes.bind(['manager', 'owner'])));
  }
}

export const UserInfoContext = createContext<UserInfo>(new UserInfo(null));

export interface UserInfoProviderProps {
  value: UserInfoProps;
  children: React.ReactNode;
}

export const UserInfoProvider =
  ({ value, children }) => React.createElement(
    UserInfoContext.Provider,
    { value: useMemo(() => new UserInfo(value), [value]) },
    children,
  );
