export const snakeToHumanReadable = (str: string) => {
  const words = str.split('_');
  return words.map((w) => w[0].toUpperCase() + w.slice(1).toLowerCase()).join(' ');
};

export const camelToSnake = (str: string) => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
export const snakeToCamel = (str: string) =>
  str.toLowerCase().replace(/([-_][\w])/g, group =>
    group
      .toUpperCase()
      .replace('_', ''),
  );

export const insertUnderscoreBeforeNumber = (str: string) => {
  return str.replace(/(\d+)/, '_$1');
};

export const stringToFormattedDecimal = (str: string, decimal: number) => {
  const number = parseFloat(str.replace('$', ''));
  return !isNaN(number) && `$${number.toFixed(decimal)}`;
};
